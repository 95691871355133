import { mdToast } from '../../../utils/tools';
export default {
  props: ['show_seq', 'seq_list',
  // 序列号列表
  'seq_url',
  // 图片
  'seq_tit',
  // 标题
  'father_seq_list_checked',
  // 序列号选中数据
  'type',
  // 1：三合一； 2：六合一
  'father_checked_nums',
  //六合一：已选中数量
  'father_checked_list' // 六合一，已选中列表
  ],
  computed: {
    show() {
      return this.show_seq;
    },
    checkedNums() {
      if (this.type == 2) {
        let num = 0;
        for (let key in this.tempObj) {
          if (this.tempObj[key]) num += 1;
        }
        return num;
      } else if (this.type == 1) {
        return this.seq_check_list.length;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      seq_check_list: [],
      tempObj: {}
    };
  },
  methods: {
    open() {
      if (this.type == 1) {
        if (this.father_seq_list_checked && this.father_seq_list_checked.length) {
          this.father_seq_list_checked.forEach(item => this.tempObj[item.stock_hash] = true);
          this.seq_check_list = JSON.parse(JSON.stringify(this.father_seq_list_checked));
        }
      } else if (this.type == 2) {
        if (this.father_seq_list_checked && this.father_seq_list_checked.length) {
          this.seq_check_list = JSON.parse(JSON.stringify(this.father_seq_list_checked));
        }
        if (this.father_checked_list && this.father_checked_list.length) {
          this.father_checked_list.forEach(item => this.tempObj[item.stock_hash] = true);
        }
      }
    },
    closePicker() {
      this.tempObj = {};
      this.seq_check_list = [];
      this.$emit('update:show_seq', false);
    },
    checkSeq(item) {
      if (item && item.stock_hash && !this.tempObj[item.stock_hash]) {
        if (this.type == 2) {
          if (this.checkedNums + 1 > 6) {
            return mdToast('最多选择6个藏品');
          } else {
            this.tempObj[item.stock_hash] = true;
            this.seq_check_list.push(item);
          }
        } else if (this.type == 1) {
          if (this.checkedNums + 1 > 3) {
            return mdToast('最多选择3个藏品');
          } else {
            this.tempObj[item.stock_hash] = true;
            this.seq_check_list.push(item);
          }
        }
      } else {
        this.tempObj[item.stock_hash] = false;
        const index = this.seq_check_list.findIndex(ele => ele.stock_hash == item.stock_hash);
        this.seq_check_list.splice(index, 1);
      }
    },
    getCheckSeq() {
      this.$emit('getCheckSeq', this.seq_check_list);
      this.closePicker();
    }
  }
};