import Error from "@@/common/Error.vue";
import lowerdev from '@/service/lowerdev';
import redirect from '@/service/redirect';
import { env, domPixelRatio } from 'md-base-tools/env';
import { mapState, mapActions } from "vuex";
import { showFailToast, showSuccessToast, showToast } from 'vant';
import { getActivityList, getMergerList, getHashAddress, cbaMergerByHash } from "@/api/index";
import { throttle } from "@/utils/tools";
import AddressEdit from "@@/digital/activity/address_edit.vue";
import AddressShow from "@@/digital/activity/address_show.vue";
import CompLoading from "@@/digital/activity/loading.vue";
import CompSeqList from "@@/digital/activity/seq_list_check.vue";
import { goToLogin } from "@/utils/user";
const __default__ = {
  components: {
    Error,
    AddressEdit,
    AddressShow,
    CompLoading,
    CompSeqList
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin,
      // 是否登录
      userId: state => state.user.userId,
      token: state => state.user.token
    }),
    isPhone() {
      return env.isModianIos() || env.isModianAndroid();
    },
    ratio() {
      return parseInt(domPixelRatio()) || 1;
    }
  },
  data() {
    return {
      showError: false,
      showMessage: '活动不存在！',
      activityData: {},
      activity_id: '',
      //活动id
      isLogin: false,
      status: '0',
      // 合成未开始
      is_merger: 0,
      // 是否可合成 0、不能合成 1、允许合成
      // pro_status: 1, // 项目是否结束
      nfts: [],
      // 获取活动藏品信息
      nfts_six: [],
      // 获取活动藏品信息-六合一
      times: 0,
      // 开奖倒计时
      times_show: "",
      timer: null,
      loading: false,
      show: false,
      showEdit: false,
      showPicker: false,
      open_prize_status: 0,
      // 是否开奖 0、未开奖 1、已开奖 2、无开奖
      prize_activity_id: '',
      // 有开奖是，查询开奖信息所需参数
      merger_list: [],
      time_seconds: '',
      // 时间
      address_info: {
        address_name: '',
        address_mobile: "",
        province: '',
        address: ''
      },
      loading_time: 0,
      timer_prize: null,
      // 序列号
      show_seq: false,
      seq_url: '',
      seq_index: '',
      seq_list: [],
      //序列号列表
      seq_tit: '',
      seq_list_checked: [],
      // 序列号选中数据--三合一
      father_checked: -1,
      //全局已选中序列号-三合一
      father_checked_list: [],
      // 全局已选中序列号列表-六合一
      // 序列号
      link_url: '',
      // 收货地址
      stock_hash: '',
      relation_id: '',
      address_type: 'add',
      // 收获地址类型
      fill_end_time: '',
      describe: '',
      btnActive: 1,
      // 按钮

      mergeTips: '',
      //合成提示
      // activity_id_map: { // 测试环境
      //     "97F02DC7AD4347038D1B0446823C5B75": '11',
      //     "F1A347C0A0BF4B65A968908B7A9E4AF4": '14',
      //     "9E659D4BC5894263838C1001BCEFB157": '15',
      //     "CAA66EECE4D4477DB9A2CF4F16806EEB": '16',
      //     "8434177E771A4E339D50C2BE5356DECA": '17',
      //     "60A68325AFBC4CD08C852D0A02A54D87": '18',
      //     "26D2942F6BEB4D48B7A9AB9E68827998": '19',
      //     "22FB4EB11DF24DEFB53536AB7514B851": '20',
      //     "16036BA8068C490787345CF1B7E1943F": '21',
      //     "79D82E082BE649CC9F0F62A28F84EF6E": '22',
      //     "1288687C3A0E41FFBBBDBD11412A031A": '23',
      //     "2BE38EBC84C84823840DF3CF0E17498E": '24'
      // }
      activity_id_map: {
        "D4F6C8D55B3D4593875E664A83A9AA76": '11',
        "22F5B3D4DAAE41078C165787117C01EF": '14',
        "6664085CDF7245299D2CD65CFD90DA46": '15',
        "2098BBF1FA0C4A658D80D6BFBE1506D3": '16',
        "DB59EB14D71C4C06878ACD83BF10D282": '17',
        "6F55313F98E647CF9240AA22A5CB6E44": '18',
        "D394FF80EA0D4DAEBAD5F280587C61DC": '19',
        "8BE67AD0F70840C5BFB321718B6285B6": '20',
        "68AEB2C5D422486AAAFC4EBAD9D4343F": '21',
        "B391B8101108463EB2B38DCBA136D49B": '22',
        "16096BE9F79C4B2AA9AD174EACAC761B": '23',
        "BD2AE566BDF64CC2A12BED3FA2F36761": '24'
      }
    };
  },
  beforeMount() {
    if (env.isWxApp()) {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.3.2.js");
    }
    const query = this.$route.query;
    if (query['MDUSERTOKEN']) {
      localStorage.setItem("MDUSERTOKEN", query['MDUSERTOKEN']);
    }
  },
  mounted() {
    const ui_id = '9';
    this.getUI(ui_id);
  },
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.timer_prize);
  },
  methods: {
    ...mapActions({
      getAddressList: "getAddressList"
    }),
    // 获取UI数据
    async getUI() {
      const data = '{"bg_top":"https://p6.moimg.net/md/nft/CP1001756883/act20220511/bg_top.png","bg_bottom":"https://p6.moimg.net/md/nft/CP1001756883/act20220511/bg_bottom.jpg","bg_color":"#5C43EF","title":"十二生肖藏品合成","activity_id1":"11","activity_id2":"12","rulesImgs":["https://p6.moimg.net/md/nft/CP1001756883/act20220511/rule.jpg"]}';
      this.activityData = JSON.parse(data);
      this.prize_activity_id = '13';
      this.link_url = this.activityData.link_url || '';
      document.title = this.activityData.title;
      this.checkLogin();
      this.getAddressList();
    },
    async getActivity() {
      var res = await getActivityList({
        activity_id: '12'
      });
      if (res.status == 0) {
        this.status = res.data.status;
        this.is_merger = res.data.is_merger;
        this.nfts = JSON.parse(JSON.stringify(res.data.nfts));
        this.nfts_six = JSON.parse(JSON.stringify(res.data.nfts));
        // this.pro_status = res.data.pro_status
        this.times = res.data.time_seconds;
        if (res.data.status == 0) {
          this.timer = setInterval(() => {
            this.times--;
            this.times_show = this.getDuration(this.times);
            if (this.times <= 0 && res.data.status == 0) {
              clearInterval(this.timer);
              window.location.reload();
            }
          }, 1000);
        }
        if (this.ifLogin && this.status != 0) {
          this.getMerger();
        }
        this.resetSeq();
      }
    },
    async getMerger() {
      let option = {
        merge_activity_id: '10000000'
      };
      const {
        status,
        data
      } = await getMergerList(option);
      if (status == 0 && data.list && data.list.length) {
        this.merger_list = data.list;
      } else {
        this.merger_list = [];
      }
      // const arr =  await Promise.all([
      //     this.getMerger1(),
      //     this.getMerger2()
      // ])
      // this.merger_list = arr.flat()
    },
    fnThrottle(fn, delay, atleast) {
      let timer = null;
      let previous = null;
      return function () {
        let now = +new Date();
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          previous = now;
          clearTimeout(timer);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            previous = null;
          }, delay);
        }
      };
    },
    // 开始合成
    goMerge: throttle(async function () {
      // 三合一
      if (this.btnActive == 1) {
        if (this.father_checked < 0) {
          this.mergeTips = '请选择3个数字藏品';
          return;
        } else {
          this.mergeTips = '';
        }
      } else if (this.btnActive == 2) {
        if (this.father_checked_list.length != 6) {
          this.mergeTips = '请选择6个数字藏品';
          return;
        } else {
          this.mergeTips = '';
        }
      } else {
        return;
      }
      const that = this;
      this.loading_time = 0;
      this.loading = true;
      let num = Math.floor(Math.random() * 5) + 1;
      let res = {};
      await Promise.all([new Promise(resolve => {
        const timer = setInterval(async () => {
          if (that.loading_time >= 100) {
            resolve(true);
            clearInterval(timer);
          } else {
            that.loading_time += 1;
          }
        }, 100);
      }), new Promise(resolve => {
        setTimeout(async () => {
          let arr = [];
          let id = '';
          if (this.btnActive == 1) {
            arr = this.nfts[this.father_checked].seq_list.map(item => item.stock_hash);
            let main_id = this.nfts[this.father_checked].main_id;
            id = this.activity_id_map[main_id];
          } else if (this.btnActive == 2) {
            arr = this.father_checked_list.map(item => item.stock_hash);
            id = '12';
          }
          res = await cbaMergerByHash({
            activity_id: id,
            consume_hash_list: JSON.stringify(arr)
          });
          resolve(true);
        }, num * 1000);
      })]);
      if (that.loading_time >= 99) {
        that.loading = false;
        if (res.status == 0) {
          if (res.data) {
            showSuccessToast('合成成功');
          } else {
            showFailToast(res.message);
          }
        } else {
          showFailToast(res.message);
        }
        that.getActivity();
      }
    }, 1000),
    // 查看收货地址
    async showPopup(stock_hash) {
      this.show = true;
      const {
        status,
        data
      } = await getHashAddress({
        stock_hash: stock_hash
      });
      if (status == 0) {
        this.address_info = data.address;
      }
    },
    // 填写收货地址
    // showPopupAdd(stock_hash, relation_id) {
    //     this.stock_hash = stock_hash
    //     this.relation_id = relation_id
    //     this.showEdit = true
    // },
    // 填写收货地址 修改收货地址
    async showPopupEdit(item, type) {
      this.stock_hash = item.stock_hash;
      this.relation_id = item.relation_id;
      this.fill_end_time = item.fill_end_time || '';
      this.describe = item.describe || '';
      this.address_type = type;
      if (type == 'edit' || type == 'disabled') {
        const {
          status,
          data
        } = await getHashAddress({
          stock_hash: item.stock_hash,
          prize_activity_id: this.prize_activity_id
        });
        if (status == 0) {
          this.address_info = Object.assign({}, data.address);
        }
      }
      this.showEdit = true;
    },
    // 查看序列号集合
    goToChoseSeq(list, url, index, title) {
      if (!list || list.length == 0) return;
      // 三合一
      if (this.btnActive == 1) {
        if (list.length < 3) {
          return;
        } else if (this.father_checked > -1 && this.father_checked != index) {
          let title = this.nfts[this.father_checked].title;
          return showToast({
            message: `已选中‘${title}’，取消后可重新选择`,
            duration: '3000'
          });
        }
        this.seq_list_checked = this.nfts[index].seq_list;
      } else if (this.btnActive == 2) {
        this.seq_list_checked = this.nfts_six[index].seq_list;
      } else {
        return;
      }
      this.seq_list = list;
      this.seq_url = url;
      this.seq_index = index;
      this.seq_tit = title;
      this.show_seq = true;
    },
    // 获取选中列表 
    getCheckSeq(list) {
      //  三合一
      if (this.btnActive == 1) {
        if (list && list.length == 3) {
          this.nfts[this.seq_index].seq_list = list;
          this.father_checked = this.seq_index;
        } else {
          this.nfts[this.seq_index].seq_list = [];
          this.father_checked = -1;
        }
      } else if (this.btnActive == 2) {
        // 六合一
        this.nfts_six[this.seq_index].seq_list = list;
        let arr = [];
        this.nfts_six.forEach(item => {
          if (item.seq_list) {
            arr.push(...item.seq_list);
          }
        });
        this.father_checked_list = arr;
      }
    },
    getDuration(second) {
      var duration;
      var days = Math.floor(second / 86400);
      var hours = Math.floor(second % 86400 / 3600);
      var minutes = Math.floor(second % 86400 % 3600 / 60);
      var seconds = Math.floor(second % 86400 % 3600 % 60);
      if (days > 0) duration = days + "天" + hours + "小时" + minutes + "分" + seconds + "秒";else if (hours > 0) duration = hours + "小时" + minutes + "分" + seconds + "秒";else if (minutes > 0) duration = minutes + "分" + seconds + "秒";else if (seconds > 0) duration = seconds + "秒";
      return duration;
    },
    // 项目未结束-跳转至项目详情
    /* eslint-disable */
    goToLink() {
      if (this.isPhone) {
        graft.app({
          functionName: "openController",
          functionParams: this.link_url
        });
      } else if (env.isWxApp()) {
        if (this.link_url.includes('project')) {
          let arr = this.link_url.split('/');
          let id = arr[arr.length - 1].replace('.html', '');
          wx.miniProgram.navigateTo({
            url: `/pages/project-detail/project-detail?pro_id=${id}`
          });
        } else if (this.link_url.includes('product')) {
          wx.miniProgram.navigateTo({
            url: `/pages/content/content?url=${this.link_url}`
          });
        }
      } else {
        location.href = this.link_url;
      }
    },
    // 检测登录信息
    checkLogin() {
      if (this.isPhone) {
        graft.app({
          functionName: "onGetUserInfo",
          functionParams: {},
          callBack: e => {
            var token = '';
            var user_id = '';
            if (e.errorCode == 0) {
              user_id = e.data.user_id ? e.data.user_id : e.data.userId;
              token = e.data.token;
              let icon = e.data.icon || "";
              localStorage.setItem("MDUSERTOKEN", user_id + "#" + token);
              localStorage.setItem("MDUSERINFO", icon);
              this.isLogin = true;
            } else {
              user_id = '';
              token = '';
              localStorage.removeItem("MDUSERTOKEN");
              localStorage.removeItem("MDUSERINFO");
              this.isLogin = false;
            }
            setTimeout(() => {
              this.getActivity();
            });
          },
          webFun: e => {
            redirect.to("login");
          }
        });
      } else if (env.isWxApp()) {
        const query = this.$route.query;
        if (query['MDUSERTOKEN'] && query['MDUSERTOKEN'].length > 3) {
          this.isLogin = true;
        } else {
          this.isLogin = false;
        }
        this.getActivity();
      } else {
        this.isLogin = this.ifLogin;
        this.getActivity();
      }
    },
    goToLogin() {
      goToLogin();
    },
    // 重置选择序号列表
    resetSeq() {
      this.father_checked = -1;
      this.seq_list_checked = [];
      this.seq_list = [];
      this.seq_url = '';
      this.seq_tit = '';
      this.father_checked_list = [];
    }
    // 三合一列表
    // async getMerger1() {
    //     let option = {
    //         merge_activity_id: '11'
    //     }
    //     const { status, data } = await getMergerList(option)
    //     if (status == 0 && data.list && data.list.length) {
    //         let list = data.list.map(item => {
    //             item.open_prize_status = 2
    //             return item
    //         })
    //         return list
    //     } else {
    //         return []
    //     }
    // },
    // 六合一列表
    // async getMerger2() {
    //     let option = {
    //         merge_activity_id: '12',
    //         prize_activity_id: '13'
    //     }
    //     const { status, data } = await getMergerList(option)
    //     if (status == 0 && data.list && data.list.length) {
    //         let list = data.list.map(item => {
    //             item.open_prize_status = 1
    //             return item
    //         })
    //         return list
    //     } else {
    //         return []
    //     }
    // },
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "b756b37c": _ctx.activityData.bg_color || "#5C43EF"
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;